import React from "react";
import DepositeTemplate from "../../../../components/dashboard/wallets/deposit";

type DepositSymbolProps = {
  params: {
    symbol: string;
  };
};

const DepositSymbol = (props: DepositSymbolProps) => {
  return <DepositeTemplate symbol={props.params.symbol} />;
};

export default DepositSymbol;
